import { computed, onMounted, ref, useRoute, useStore, reactive, useContext } from '@nuxtjs/composition-api'
import introJs from 'intro.js'
import feedbackDialog from '@/components/home/feedbackDialog.vue'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { onlineServiceUrl } from '@/constant'
import 'intro.js/introjs.css'


export default {
  __name: 'ScrollTool',
  setup(__props) {

const context = useContext()
const { userInfo, corps, loginCorpId } = useLoginInfo()
const { push, pushBcs } = useLink()
const { judgeIsLogin } = useMsgDialog()
const route = useRoute()
const whitePath = [
  '/',
  '/shop',
  '/shop/goods',
  '/oddments',
  '/specialPrice',
  '/bid/single',
  '/bid/pack',
  '/transport',
  '/transportEnquiry',
  '/news',
  '/shop/carts'
]
const showRoute = computed(() => {
  const nowPath = route.value.path
  return whitePath.includes(nowPath)
})
const noShowCart = computed(() => {
  const nowPath = route.value.path
  const whitePath2 = ['/shop/carts']
  return !whitePath2.includes(nowPath)
})
const totop = ref(false)
const tobottom = ref(false)
const speed = 100 * 1000
const scrolltotop = (e) => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop > 400) {
    totop.value = true
  } else {
    totop.value = false
  }
  const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
  const scrollHeight = document.documentElement.scrollHeight
  const rollheight = scrollHeight - clientHeight
  const top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
  if (rollheight <= top + 400) {
    tobottom.value = false
  } else {
    tobottom.value = true
  }
  if (top == 0) {
    tobottom.value = false
  }
}
const backTOP = () => {
  const back = setInterval(() => {
    if (document.body.scrollTop || document.documentElement.scrollTop) {
      document.body.scrollTop -= speed
      document.documentElement.scrollTop -= speed
    } else {
      clearInterval(back)
    }
  })
}

// 点击回到页面底部
const toBottom = (i) => {
  const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
  const scrollHeight = document.documentElement.scrollHeight
  const rollheight = scrollHeight - clientHeight // 超出窗口上界的值就是底部的scrolTop的值
  document.documentElement.scrollTop += speed
  let c
  if (document.documentElement.scrollTop + 1 <= rollheight) {
    // 之所以+1，可以打印这两个值的日志就知道了，下面+1也是同理
    c = setTimeout(() => toBottom(i), 10) // 调用setTimeout是为了“回到底部”这过程不是一瞬间
  } else {
    clearTimeout(c)
  }
}

const feedbackRef = ref(null)
const handleFeedback = () => {
  if (!userInfo.value) {
    judgeIsLogin(true)
    return
  }
  feedbackRef.value.resultFlag = false
  feedbackRef.value.formRef && feedbackRef.value.formRef.resetFields()
  feedbackRef.value.dialogVisible = true
}

const handleContract = () => {
  pushBcs(onlineServiceUrl, false)
}

const handleCart = () => {
  if (!userInfo.value) {
    judgeIsLogin(true, '/shop/carts')
    return
  }
  push('/shop/carts')
}
const store = useStore()
const cartsNum = computed(() => store.getters.cartsNumber + 0)

// 新手指引
const introOption = reactive({
  prevLabel: '上一步',
  nextLabel: '下一步',
  doneLabel: '立即体验', // 完成按钮文字
  hidePrev: true, // 在第一步中是否隐藏上一个按钮
  hideNext: false, // 在最后一步中是否隐藏下一个按钮
  exitOnOverlayClick: false, // 点击叠加层时是否退出介绍
  showStepNumbers: false, // 是否显示红色圆圈的步骤编号
  disableInteraction: false, // 是否禁用与突出显示的框内的元素的交互，就是禁止点击
  showBullets: false, // 是否显示面板指示点
  overlayClickThrough: true, // 允许点击事件穿透遮罩层
  tooltipPosition: 'left' /* 当位置选择自动的时候，位置排列的优先级 */,
  highlightClass: 'intro-highlight',
  tooltipClass: 'intro-tooltip',
  buttonClass: 'intro-button',
  overlayOpacity: 0.4,
  steps: []
})

const startGuide = () => {
  introOption.steps = [
    { title: '联系客服', element: '#step1', intro: '在线客服上线啦！鼠标点击或悬停扫描二维码即可在线咨询，实现应答秒响应！' },
    { title: '联系客服', element: '#step1', intro: '点击在线客服按钮后，在新跳转的页面点击打开wechat即可无需添加好友发起咨询！' }
  ]
  introJs().setOptions(introOption).start()
}

onMounted(async () => {
  window.addEventListener('scroll', scrolltotop)
  if (
    userInfo.value &&
    !localStorage.getItem(`isGuideVisible-${userInfo.value.loginCorpId}`) &&
    showRoute.value &&
    (corps.value.length == 0 || (corps.value.length && loginCorpId.value))
  ) {
    const { data, statusCode } = await context.$api.auth.getGuideCheck({ mobile: userInfo.value.mobile })
    if (statusCode == 200 && !!data) {
      setTimeout(() => {
        startGuide()
        localStorage.setItem(`isGuideVisible-${userInfo.value.loginCorpId}`, 1)
      })
    }
  }
})

return { __sfc: true,context, userInfo, corps, loginCorpId, push, pushBcs, judgeIsLogin, route, whitePath, showRoute, noShowCart, totop, tobottom, speed, scrolltotop, backTOP, toBottom, feedbackRef, handleFeedback, handleContract, handleCart, store, cartsNum, introOption, startGuide, feedbackDialog }
}

}